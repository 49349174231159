<template>

    <div class="">
        <v-tabs
            v-model="selectedTab"
            class="custom-tabs-container"
        >
            <v-tab key="tab-1">
                <v-icon left>mdi-arrow-bottom-left</v-icon>
                {{ $t('component_linkingTablesContainer_tabTitle_backlink') }}
            </v-tab>
            <v-tab key="tab-2">
                <v-icon left>mdi-web-check</v-icon>
                {{ $t('component_linkingTablesContainer_tabTitle_website') }}
            </v-tab>
            <v-tab key="tab-3">
                <v-icon left>mdi-anchor</v-icon>
                {{ $t('component_linkingTablesContainer_tabTitle_anchor') }}
            </v-tab>
            <v-tab key="tab-4">
                <v-icon left>mdi-page-next-outline</v-icon>
                {{ $t('component_linkingTablesContainer_tabTitle_page') }}
            </v-tab>



        </v-tabs>

        <v-tabs-items v-model="selectedTab" class="custom-tabs-item-container" style="min-height: 900px">
            <v-tab-item key="tab-1">
                <linking-backlinks/>
            </v-tab-item>
            <v-tab-item key="tab-2">
                <linking-ref-domains/>
            </v-tab-item>
            <v-tab-item key="tab-3">
                <linking-anchors/>
            </v-tab-item>
            <v-tab-item key="tab-4">
                <linking-top-pages/>
            </v-tab-item>

        </v-tabs-items>


    </div>


</template>


<script>

import LinkingBacklinks from "@/components/yooda-components/app/linking/LinkingBacklinks.vue";
import LinkingRefDomains from "@/components/yooda-components/app/linking/LinkingRefDomains.vue";
import LinkingAnchors from "@/components/yooda-components/app/linking/LinkingAnchors.vue";
import LinkingTopPages from "@/components/yooda-components/app/linking/LinkingTopPages.vue";

export default {
    data() {
        return {
            selectedTab:null
        }
    },
    components: {
        LinkingBacklinks,
        LinkingRefDomains,
        LinkingAnchors,
        LinkingTopPages,
    },

    beforeMount() {
    },
}
</script>

