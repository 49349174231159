<template>
    <page-placeholder v-if="project.isLoaded && hasLinking()">
        <v-row>
            <v-col :xl="4" :lg="4" :cols="12">
                <linking-popularity-by-website/>
            </v-col>
            <v-col :xl="4" :lg="4" :cols="12">
                <linking-website-authority/>
            </v-col>
            <v-col :xl="4" :lg="4" :cols="12">
                <linking-link-count/>
            </v-col>


            <v-col :xl="6" :lg="6" :cols="12">
                <linking-my-thematic-authority/>
            </v-col>
            <v-col :xl="6" :lg="6" :cols="12">
                <linking-authority-compare/>
            </v-col>



            <v-col :cols="12">
                <linking-competitors-linking/>
            </v-col>



            <v-col :cols="12">
                <linking-tables-container/>
            </v-col>

        </v-row>
    </page-placeholder>
    <div class="text-center v-application" v-else>
        <v-card v-if="showError">
            <v-card-text class="pa-10">
                <h3 class="text-h4 mb-10">{{ $t('view_linking_unauthorized_title') }}</h3>
                <p class="text-body-1">{{ $t('view_linking_unauthorized_text') }}</p>
                <p class="text-body-1">{{ $t('view_linking_unauthorized_ps') }}</p>
                <img src="@/assets/images/pages/unauthorizedLinking.webp" alt="smart" class="mx-auto mt-8">
            </v-card-text>
        </v-card>
    </div>

</template>


<script>

import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";

import LinkingPopularityByWebsite from "@/components/yooda-components/app/linking/LinkingPopularityByWebsite.vue";
import LinkingWebsiteAuthority from "@/components/yooda-components/app/linking/LinkingWebsiteAuthority.vue";
import LinkingLinkCount from "@/components/yooda-components/app/linking/LinkingLinkCount.vue";

import LinkingCompetitorsLinking from "@/components/yooda-components/app/linking/LinkingCompetitorsLinking.vue";

import LinkingMyThematicAuthority from "@/components/yooda-components/app/linking/LinkingMyThematicAuthority.vue";
import LinkingAuthorityCompare from "@/components/yooda-components/app/linking/LinkingAuthorityCompare.vue";

import LinkingTablesContainer from "@/components/yooda-components/app/linking/LinkingTablesContainer.vue";

import {mapGetters} from "vuex";

export default {
    data() {
        return {
            showError:false
        }
    },

    computed:{
        ...mapGetters({
            hasLinking:"customersManager/getHasLinking",
        }),
    },

    components: {
        PagePlaceholder,
        LinkingPopularityByWebsite,
        LinkingWebsiteAuthority,
        LinkingLinkCount,
        LinkingCompetitorsLinking,
        LinkingMyThematicAuthority,
        LinkingAuthorityCompare,
        LinkingTablesContainer
    },

    mounted() {
        setTimeout(()=>{
            this.showError = true
        },1000)
    }

}

</script>


